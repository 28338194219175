import { paynow } from '@/api/Ge'
import { openPay } from './index'
import qs from 'qs'

function payment (orderId, type) {
  if (type == 4) {
    paynow(qs.stringify({
      orderId,
      paymentMode: type
    })).then(res => {
      if (res.data.code === 0) {
        openPay(res, '1')
      }
    })
  } else if (type == 3) {
    paynow(qs.stringify({
      orderId,
      paymentMode: type
    })).then(res => {
      if (res.data.code === 0) {
        openPay(res.data, '2')
      }
    })
  }
}

export {
  payment
}