<template>
  <div style="background: #F6F6F6; height: 100%;">
    <van-nav-bar
      title="提交订单"
      left-arrow
      fixed
      placeholder
      @click-left="$router.back()"
    />

    <!-- 主体内容 -->
    <div class="contentBox">
      <div class="content">
        <div class="selec">
          <div @click="byOutSell" :class="{
            active: order.by === 'outSell'
          }">外卖配送</div>
          <div @click="byOnesele" :class="{
            active: order.by === 'onesele'
          }">到店自取</div>
        </div>

        <div class="filter" v-show="type==1?true:false">
          <p
            @click="address"
            style="color: #FF7200; display: flex; align-items: center;"
            v-if="JSON.stringify(userAddress) == '{}' ? true : false"
          >
            <van-icon name="add-o" />&nbsp;&nbsp;
            <span>新增收货地址</span>
          </p>
          <p v-if="JSON.stringify(userAddress) != '{}' ? true : false ">
            <span style="font-size: 12px;">{{ userAddress.addressHeader }}</span>
          </p>
          <p
            style="font-size: 14px; 
              font-weight: bold;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 7px 0 4px;"
            v-if="JSON.stringify(userAddress) != '{}' ? true : false "
            @click="address"
          >
            <span>{{ userAddress.address }}</span>
            <span><van-icon name="arrow" /></span>
          </p>
          <p
            style="font-size: 12px; color: #999;"
            v-if="JSON.stringify(userAddress) != '{}' ? true : false "
          >
            <span>{{ userAddress.name }}</span>
            <span>{{ userAddress.phone }}</span>
          </p>
          <div style="font-size: 12px; display: flex; align-items: center; margin-top: 12px;">
            <span style="flex: 1;">立即送出</span>
            <span style="color: #FF5200;">大约{{ deliveryhTime }}送达</span>
          </div>
        </div>

        <div class="fetch" v-show="type==2?true:false">
          <p style="font-size: 12px; color: #333;">商家地址</p>
          <p style="font-size: 14px; padding-bottom: 12px; border-bottom: 1px solid #F1F1F1; margin: 1px 0 9px;">
            <span style="font-weight: bold; margin-right: 4%;">{{ sellerAddress }}</span>
            <span>距您{{ userToSellerDistance }}m</span>
          </p>
          <div class="Ge-xian-bo" style="font-size: 14px; color: #999;">
            <van-row>
              <van-col :span="12" style="border-right: 1px solid #F1F1F1;">
                <p>自取时间</p>
                <div style="display: flex; align-items: center; width: 55px; justify-content: space-between;">
                  <span style="color: #333;">09:45</span>
                  <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng6cdf24b71060035d4b52f3b38dcc6345e718058f2054dad0296fdffdbe76bfd9" alt="">
                </div>
              </van-col>
              <van-col :span="12">
                <p style="margin-left: 16px;">预留电话</p>
                <van-cell-group :border="false" >
                  <van-field 
                    size="meddle" 
                    v-model="value" 
                    placeholder="请输入手机号码"
                    clearable
                  />
                </van-cell-group>
              </van-col>
            </van-row>
          </div>
        </div>

        <div class="payType" style="margin-top: 10px; background: white; padding: 0 3.5%; margin-bottom: 10px; border-radius: 5px;">
          <van-radio-group v-model="checked">
            <van-cell-group inset>
              <van-cell icon="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng1332eea59f04af1f4403bcf19da117119b3b343fe56cd8c1ab738224c65e9ff8" title="支付宝" @click="getChecked('3')">
                <template #right-icon>
                  <van-radio icon-size="14px" checked-color="#ff5a00" name="3" />
                </template>
              </van-cell>
              <van-cell icon="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng99872f5bfec5a2cbe5f4302864198b84373997e428beec81683cb4a7f30b80ef" title="微信" @click="getChecked('4')">
                <template #right-icon>
                  <van-radio icon-size="14px" checked-color="#ff5a00" name="4" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>

        <div style="padding: 0 3.5% 10px; background: #fff;">
          <p style="font-weight: bold; padding: 10px 0;">{{ $route.query.shopName }}</p>
          <div>
            <div 
              style="padding: 10px 0; 
              display: flex; 
              border-bottom: 1px solid #F6F6F6;"
              v-for="(item, index) in shopCar"
              :key="index"
            >
              <img style="width: 55px; height: 55px; margin-right: 10px;" :src="item.goodsImages" alt="">
              <div style="flex: 1;">
                <div style="font-size: 14px; display: flex; justify-content: space-between;">
                  <span>{{ item.goodsName }}</span>
                  <span>￥{{ item.price.toFixed(2) }}</span>
                </div>
                <div style="margin: 3px 0; font-size: 12px;">
                  <van-tag 
                    color="#999" 
                    style="margin-right: 10px;"
                    v-for="(item2, index) in item.specificationShow"
                    :key="index"
                  >{{ item2 }}</van-tag>
                </div>
                <p style="font-size: 12px;">x {{ item.quantity }}</p>
              </div>
            </div>
          </div>
          <div style="font-size: 12px; display: flex; justify-content: space-between; margin-top: 9px;">
            <span>打包费</span>
            <span>￥{{ packingPrice }}</span>
          </div>
          <div style="font-size: 12px; display: flex; justify-content: space-between; margin-top: 9px;">
            <span>配送费</span>
            <span>￥{{ transportUserPrice }}</span>
          </div>
        </div>

        <div class="coupons" style="margin-top: 10px; border-radius: 5px;">
          <!-- 优惠券单元格 -->
          <van-coupon-cell
            :coupons="coupons"
            :chosen-coupon="chosenCoupon"
            @click="showList = true"
          />
          <!-- 优惠券列表 -->
          <van-popup
            v-model="showList"
            round
            position="bottom"
            style="padding-top: 4px;"
          >
            <van-coupon-list
              :show-exchange-bar="false"
              :coupons="coupons"
              :chosen-coupon="chosenCoupon"
              :disabled-coupons="disabledCoupons"
              @change="onChange"
            />
          </van-popup>
        </div>

        <div class=".van-cell">
          <van-cell 
            :clickable="false" 
            title="备注"
            is-link 
            :value="userRemark ? userRemark : '口味、偏好等要求'"
            @click="$router.push({
              path: '/userRemark',
              query: {
                userRemark
              }
            })"
          />
        </div>
      </div>
    </div>
    <!-- /主体内容 -->
    <van-submit-bar :price="totalPrice * 100" button-text="提交订单" @submit="onSubmit" />
  </div>
</template>

<script>
import { findinfo, saveorders, findaddres, distanceTime } from '@/api/Ge'
import { mapState, mapMutations } from 'vuex'
import{ payment } from '@/common/takeOut'
import qs from 'qs'
  export default {
    data () {
      return {
        type: 1,
        order: {
          by: 'outSell' // outSell 外卖配送 onesele 自取
        },
        value: '',
        // 优惠卷
        showList: false,
        chosenCoupon: -1,
        coupons: [],
        disabledCoupons: [],
        transportDistance: '', // 配送距离

        packingPrice: null,
        transportUserPrice: null, // 用户配送费
        priceTransportPost: null, // 骑手配送给
        totalPrice: 0,
        userToSellerDistance: '',
        transportType: 0, // 配送方式
        emptyType: false,
        orderId: '', // 订单号
      }
    },
    created () {
      this.setRem()
      const origin = this.lotLat.join() // 出发点/用户经纬度
      const destination = this.distance.join() // 目的地/商家经纬度
      this.distanceTime(origin, destination)
      this.transportTime()
    },
    mounted () {
      window.sendAddress = this.getUserAddress
      // window.closePage = this.closePage
      this.findaddres()
      console.log(this.$store.state.shopCar.businussTime, '营业时间')
    },
    beforeDestroy () {
      if (this.emptyType) {
        this['shopCar/empty_shop_car']()
      }
    },
    computed: {
      ...mapState({
        userRemark: state => state.shopCar.userRemark,
        shopCar: state => state.shopCar.shopCar,
        lotLat: state => JSON.parse(JSON.stringify(state.shopCar.lotLat)),
        sex: state => state.shopCar.sex,
        sellerAddress: state => state.shopCar.sellerAdress,
        distance: state => JSON.parse(JSON.stringify(state.shopCar.distance)),
        fullReduces: state => JSON.parse(JSON.stringify(state.shopCar.fullReduces)),
        userAddress: state => JSON.parse(JSON.stringify(state.shopCar.userAddress)),
        deliveryhTime: state => state.shopCar.deliveryhTime,
        expectDeliveTime: state => state.shopCar.expectDeliveTime,
        checked: state => state.shopCar.checked,
      }),

      goodsUrl () {
        return JSON.parse(JSON.stringify(this.$store.getters['shopCar/shopImg']))
      },

      allGoods () {
        return JSON.parse(JSON.stringify(this.$store.getters['shopCar/allGoods']))
      },

      shopPrice () {
        return JSON.parse(JSON.stringify(this.$store.getters['shopCar/shopPrice']))
      }
    },
    methods: {
      ...mapMutations([
        'shopCar/transport_User_Price',
        'shopCar/set_sex',
        'shopCar/get_userAddress',
        'shopCar/get_deliveryhTime',
        'shopCar/get_expectDeliveTime',
        'shopCar/get_checked',
        'shopCar/empty_shop_car',
        'setSession'
      ]),
      // 支付方式
      getChecked (param) {
        console.log(param)
        // if (param == '3') {
          this['shopCar/get_checked'](param)
        // }
      },
      // 外卖配送 自取交互
      byOutSell () {
        this.transportType = 0
        this.type = 1
        if (this.order.by === 'onesele') {
          this.order.by = 'outSell'
        }
      },
      byOnesele () {
        this.transportType = 1
        this.type = 2
        if (this.order.by === 'outSell') {
          this.order.by = 'onesele'
        }
      },
      setRem () {
        const width = document.documentElement.getBoundingClientRect().width
        document.documentElement.style.fontSize = width/10 + 'px'
      },

      // 优惠卷
      onChange(index) {
        this.showList = false;
        this.chosenCoupon = index;
        let totalPrice = this.totalPrice
        totalPrice = (totalPrice - this.coupons[index].reduce).toFixed(2)
        if (totalPrice > 0) {
          this.totalPrice = totalPrice
        } else if (totalPrice <= 0) {
          this.totalPrice = 0
        }
      },

      // 提交订单
      onSubmit () {
        if (this.transportType == 0) {
          if (JSON.stringify(this.userAddress) == '{}') {
            this.$toast('请选择收货地址')
            return
          }
        }
        if (this.transportType == 1) {
          const rule = /^1[3456789]\d{9}$/
          if (this.value == '' || this.value == null) {
            this.$toast('请输入手机号码')
            return
          }
          if (!rule.test(this.value)) {
            this.$toast('请输入正确的手机号码')
            return
          }
        }
        const payFee = this.chosenCoupon != -1 ? 
        parseFloat(this.shopPrice[1]) + parseFloat(this.packingPrice) + parseFloat(this.transportUserPrice) - parseFloat(this.coupons[this.chosenCoupon].reduce)
        : parseFloat(this.shopPrice[1]) + parseFloat(this.packingPrice) + parseFloat(this.transportUserPrice)

        const params = qs.stringify({
          shopId: this.$route.query.shopId,
          shopName: this.$route.query.shopName,
          acceptName: this.userAddress.name, // userAddress.name
          acceptPhone: this.transportType == 0 ?  this.userAddress.phone : this.value,
          // expectDeliveTime: '2022-03-19 15:51:30', // 预计送达
          expectDeliveTime: this.expectDeliveTime, // 预计送达
          payType: this.checked, // 3支付宝 4 微信
          payFee: payFee.toFixed(2), // 总支付费用
          priceGoods: parseFloat(this.shopPrice[1]), // 商品总价
          pricePackage: this.packingPrice, // 打包费
          priceTransportUser: this.transportUserPrice, // 用户配送费
          priceTransportPost: this.transportPostPrice, // 骑手配送费
          allGoods: this.allGoods,
          goodsUrl: this.goodsUrl,
          couponReduceId: this.chosenCoupon != -1 ? this.coupons[this.chosenCoupon].id : null, // 优惠券id
          priceCouponReduce: this.chosenCoupon != -1 ? this.coupons[this.chosenCoupon].reduce : null, // 优惠券
          userRemark: this.userRemark,
          fullReduce: null, // 满减规则
          priceFullReduce: null, // 满减金额
          transportType: this.transportType, // 配送方式
          transportDistance: this.transportDistance, // 配送距离
          // transportDistance: 1, // 配送距离
          sex:  this.userAddress.sex, // userAddress.sex
          lot: this.userAddress.longitude, // 经度 userAddress.longitude
          lat: this.userAddress.latitude, // 维度 userAddress.latitude
          acceptAddress: this.userAddress.addressHeader + this.userAddress.address //尚泽大都会 userAddress.addressHeader + userAddress.door
        })
        saveorders(params).then(res => {
          if (res.data.code === 0) {
            this.emptyType = true
            this.orderId = res.data.data.orderId
            payment(res.data.data.orderId, this.checked, this.totalPrice)
          } else {
            this.$toast(res.data.msg)
          }
        })
      },

      address  () {
        try{
          window.webkit.messageHandlers.getAddress.postMessage(this.$route.query.shopId)
        }catch{ 
          window.android.getAddress(this.$route.query.shopId)
        }
      },

      // 订单结算
      findinfo (distance) {
        findinfo(qs.stringify({
          shopId: this.$route.query.shopId,
          distance: distance,
          idArray: this.$store.getters['shopCar/idArray']
        })).then(res => {
          if (res.data.code === 0) {
            this.packingPrice = res.data.data.packingPrice
            this.transportUserPrice = res.data.data.transportUserPrice
            this.transportPostPrice = res.data.data.transportPostPrice
            this.totalPrice = (parseFloat(this.shopPrice[1]) + parseFloat(this.packingPrice) + parseFloat(this.transportUserPrice)).toFixed(2)
            const coupons = res.data.data.allCoupon
            coupons.forEach(item => {
              item.originCondition = 0
              item.value = item.reduce * 100
              item.name = '优惠券'
              item.startAt = new Date((item.validBeginTime.replace(/-/g, '/'))).getTime()
              item.endAt = new Date((item.validEndTime.replace(/-/g, '/'))).getTime()
              item.valueDesc = item.reduce
              item.unitDesc = '元'
              let totalPrice = this.totalPrice
              let bagSmall = (totalPrice - item.reduce).toFixed(2)
               if (item.useStatus == '2' || bagSmall <= 0) {
                this.disabledCoupons.push(item)
              } else {
                this.coupons.push(item)
              }
            })
          }
        })
      },

      // 选择收货地址
      getUserAddress (res) {
        // this.userAddress = JSON.parse(res)
        const userAddress = JSON.parse(res)
        this['shopCar/get_userAddress'](userAddress)
        const origin = userAddress.longitude + ',' + userAddress.latitude
        const destination = this.distance.join()
        this.distanceTimeTwo(origin, destination)
      },

      closePage () {
        this.$router.push({
          path: '/takeOutOrderDetail',
          query: {
            id: this.orderId
          }
        })
      },

      // 用户最近地址
      findaddres () {
        findaddres(qs.stringify({
          lot: this.lotLat[0],
          lat: this.lotLat[1],
          shopId: this.$route.query.shopId
        })).then(res => {
          if (res.data.code === 0) {
            if (res.data.data.length && res.data.data.overRange == true) {
              const address = res.data.data[0]
              const userAddress = {}
              userAddress.addressHeader = address.address
              userAddress.phone = address.phone
              userAddress.latitude = address.lat
              userAddress.longitude = address.lot
              userAddress.name = address.name
              userAddress.address = address.door
              userAddress.sex = address.sex
              const origin = address.lot + ',' + address.lat
              const destination = this.distance.join()
              this.distanceTimeTwo(origin, destination)
              this['shopCar/get_userAddress'](JSON.parse(JSON.stringify(userAddress)))
            }
          }
        })
      },

      // 展示立即送达时间
      transportTime () {
        if (JSON.stringify(this.userAddress) == '{}') {
          const date = new Date()
          date.setMinutes(date.getMinutes() + 30)
          let hour = date.getHours()
          let minute = date.getMinutes()
          if (hour < 10) {
            hour = '0' + hour
          }
          if (minute < 10) {
            minute = '0' + minute
          }
          const time = `${hour}:${minute}`
          this['shopCar/get_deliveryhTime'](time)
        }
      },

      // 到店自取 地址 距离
      distanceTime (origin, destination) {
        distanceTime(origin, destination).then(res => {
          if (res.data.errcode === 0) {
            const distance = res.data.data.paths[0].distance
            this.userToSellerDistance = distance
            this.findinfo(1)
          }
        })
      },

      // 提交配送时间配送距离
      distanceTimeTwo (origin, destination) {
        distanceTime(origin, destination).then(res => {
          if (res.data.errcode === 0) {
            let time = res.data.data.paths[0].duration + 600
            let distance = (res.data.data.paths[0].distance) / 1000
            this.transportDistance = distance
            let date = new Date()
            date.setSeconds(date.getSeconds() + time)
            let year = (date.getFullYear()).toString()
            let month = (date.getMonth() + 1).toString()
            let day = (date.getDate()).toString()
            let hour = (date.getHours()).toString()
            let minute = (date.getMinutes()).toString()
            let second = (date.getSeconds()).toString()
            if (month < 10) {
              month = '0' + month
            }
            if (day < 10) {
              day = '0' + day
            }
            if (hour < 10) {
              hour = '0' + hour
            }
            if (minute < 10) {
              minute = '0' + minute
            }
            if (second < 10 ) {
              second = '0' + second
            }
            const deliveryhTime = hour + ':' + minute
            const expectDeliveTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`
            this['shopCar/get_deliveryhTime'](deliveryhTime)
            this['shopCar/get_expectDeliveTime'](expectDeliveTime)
          }
        })
      },
    }
  }
</script>

<style lang="less" scoped>
.contentBox {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  padding-bottom: 55px;
}
.content {
  width: 96%;
} 
.selec {
  display: flex;
}
.selec div {
  width: 4.7838rem;
  height: 0.7838rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.3243rem;
}
.active {
  background: white;
}
.filter {
  background: white;
  padding: 10px 3% 15px;
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;
}
.fetch {
  background: white;
  border-radius: 0 0 5px 5px;
  padding: 10px 3% 9px;
  .van-cell-group {
    border: 0 solid red !important;
  }
}
.fetch .van-cell {
  padding: 0 16px;
  margin-top: 0;
}
.coupons .van-cell {
  padding: 10px 3.5% !important;
}
.coupons .van-cell__value span {
  padding: 2;
  font-size: 14px;
}
.coupons .van-cell .van-cell__right-icon {
  color: rgb(37, 36, 36);
}
/deep/ .coupons .van-coupon__condition {
  display: none;
}
.payType {
  .van-cell {
    padding: 10px 0;
  }
  .van-cell-group--inset {
    margin: 0;
  }
  .van-icon__image {
    margin-top: 4px;
    width: 20px;
  }
  img {
    width: 100%;
  }
}
.van-cell {
  margin-top: 10px;
  .van-cell {
    padding: 0;
  }
  .van-cell__right-icon {
    color: black;
  }
}
</style>